import Library from "./library";
import Repository from "./repository";
import componentsData from "../../../data/software/libraries.json";
import repositoriesData from "../../../data/software/repositories.json";

function Software() {
    const repositories = repositoriesData.map(repository => <Repository key={repository.full_name} repository={repository} />);
    const components = componentsData.map(repository => <Library key={repository.full_name} repository={repository} />);

    return <section className="container section">
        <h2 className="is-size-2 mb-3">Software</h2>

        <h3 className="is-size-3 mb-3">Repositories</h3>
        <div className="columns is-multiline">
            {repositories}
        </div>

        <h3 className="is-size-3 mb-3">Components</h3>
        <div className="columns is-multiline">
            {components}
        </div>
    </section>;
};

export default Software;