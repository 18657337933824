function Presentation({ inHeader }) {
    return <>
        <h1 className={`mt-5 mb-4 is-size-4 ${inHeader ? "has-text-white" : ""}`}>High Performance Artificial Intelligence research group</h1>
        {/* <p className={`no-margin-bottom pb-5 is-size-5 ${inHeader ? "has-text-white" : ""}`}>
            Welcome to the web site of the High Performance Artificial Intelligence research group (HPAI), within the Barcelona Supercomputing Center (BSC), as part of the Computer Science department.
            The group performs research in Artificial Intelligence, focused on the solutions, problems and infrastructure provided by High Performance Computing.
        </p> */}
    </>;
}

export default Presentation;