import './air.css'

import { Remarkable } from 'remarkable';
import RemarkableReactRenderer from 'remarkable-react';
import Presentation from "../home/presentation";
import React from 'react';

class FinalProjects extends React.Component {
    constructor() {
        super();
        this.state = {markdown: ""}
    }

    componentDidMount() {
        fetch("/final-projects.md")
            .then((res) => {
                res.text().then((res) => this.setState({markdown: res}))
            });
    }

    render () {
        const md = new Remarkable();
        md.renderer = new RemarkableReactRenderer();
        console.log(this.state.markdown);

        return <section className="container section">
            <div className="is-hidden-desktop">
                <Presentation />
            </div>
            { md.render(this.state.markdown) }
        </section>;
    }
}

export default FinalProjects;