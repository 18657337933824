import { Link, useLocation } from "react-router-dom";

import { animateScroll as scroll } from "react-scroll";
import { useState } from "react";

const routes = [
    { text: 'Home', url: '/' },
    { text: 'About', url: '/about' },
    { text: 'Research', url: '/research' },
    { text: 'Publications', url: '/publications' },
];

function NavBar() {
    const [isActive, setIsActive] = useState(false);
    const selectedTab = useLocation().pathname;

    return <div className="footer sticky p-0">
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <div className="navbar-item">
                    <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/images/hpai_logo.png`} alt="hpai-logo" />
                    </Link>
                </div>

                <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false"
                   data-target="navbar-hpai" onClick={() => setIsActive(!isActive)}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbar-hpai" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                <div className="navbar-start">
                </div>
                <div className="navbar-end">
                    {routes.map(({ text, url }) => (
                        <div key={text} className="navbar-item">
                            <Link to={url} onClick={() => { scroll.scrollToTop(); setIsActive(false) }}>
                                <strong className={`heading is-size-6 tabs p-2 ${selectedTab === url ? 'selected-tab' : ''}`}>
                                    {text}
                                </strong>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </nav>
    </div >;
};

export default NavBar;
