import Features from "./features";
import Works from "./work";
import News from "./news";
import Presentation from "./presentation";

function Home() {
    return <div className="home-container section">
        <Features title="High Performance Artificial Intelligence" />
        <Works />
    </div>;
};

export default Home;