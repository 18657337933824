function ResearchPolicyGraphs() {
    return <section className="container section">
        <h2 className="is-size-3 mb-5">Policy Graphs</h2>
        <div className="columns is-multiline mb-4 display-linebreak">
            <p>
            The adoption of algorithms based on Artificial Intelligence (AI) has been rapidly increasing during the last years.
            However, some aspects of AI techniques are under heavy scrutiny. For instance, in many use cases, it is not clear whether
            the decisions of an algorithm are well-informed and conforming to human understanding. Having ways to address these
            concerns is crucial in many domains, especially whenever humans and intelligent (physical or virtual) agents must
            cooperate in a shared environment.
            <br /><br />
            Policy Graphs (PGs) are an explainability method that represents the behavior of opaque agents by observing how they
            interact with their environment. This method produces a graph in which nodes represent states of the environment and
            edges between nodes represent transitions between states via performing an action. To constrain the size of the PG,
            the observation and action spaces are usually discretized into sets of propositional logic predicates.
            <br /><br />
            We have demonstrated this explainability method in both single- and multi-agent settings, including multi-agent
            cooperative environments.
            </p>
        </div>
        <embed src="/images/work/overcooked-pg.gif"  width="1000px"  />
        <div>
            <p>Publications in this research line:
            <ul>
                <li><p>
                        DOMENECH i VILA, M.; GNATYSHAK, D.; TORMOS, A.; GIMENEZ-ABALOS , V.; ALVAREZ-NAPAGAO, S.
                        Explaining the Behaviour of Reinforcement Learning Agents in a Multi-Agent Cooperative Environment Using Policy Graphs.
                        Electronics 2024, 13, 573. https://doi.org/10.3390/electronics13030573
                </p></li>
                <li><p>
                        TORMOS, A.; GIMENEZ-ABALOS , V.;  GNATYSHAK, D.; ALVAREZ-NAPAGAO, S.
                        Policy graphs in action: explaining single- and multi-agent behaviour using predicates. 2023.
                        XAI in Action: Past, Present, and Future Applications.
                </p></li>
                <li><p>
                        TORMOS, A.; GIMENEZ-ABALOS , V.; DOMENECH i VILA, M.; GNATYSHAK, D.; ALVAREZ-NAPAGAO, S.; VAZQUEZ-SALCEDA, J.
                        Explainable agents adapt to human behaviour. 2023.
                        In Proceedings of the First International Workshop on Citizen-Centric Multi-Agent Systems (CMAS'23) (pp. 42-48).
                </p></li>
                <li><p>
                        DOMENECH i VILA, M.; GNATYSHAK, D.; TORMOS, A.; ALVAREZ-NAPAGAO, S.
                        Testing Reinforcement Learning Explainability Methods in a Multi-Agent Cooperative Environment. 2022.
                        In Artificial Intelligence Research and Development (pp. 355-364). IOS Press.
                </p></li>
                <li><p>
                        CLIMENT, A.; GNATYSHAK, D.; ALVAREZ-NAPAGAO, S.
                        Applying and verifying an explainability method based on policy graphs in the context of reinforcement learning. 2021.
                        In Artificial Intelligence Research and Development (pp. 455-464). IOS Press.
                </p></li>
            </ul>
            </p>
        </div>
    </section>;
}

export default ResearchPolicyGraphs;
