function ResearchCard({ information: { title, description, image, innerRef } }) {
    return <a href={innerRef}>
      <div className="card mb-4">
    <div className="card-content pt-5">
        <div className="flex-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className="content" style={{ flex: 1 }}>
                <p>
                    <span className="title is-5">{title}</span> 
                </p>
                <div className="content mb-1">
                    <p><strong>Description:</strong> {description}</p>
                </div>
            </div>
            <img src={image} style={{ maxWidth: '300px', maxHeight: '300px', objectFit: 'contain', flex: 1}} alt="alt" />
        </div>
    </div>
</div>

    </a>;
};

export default ResearchCard;