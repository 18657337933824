function Paper({ information: { title, authors, abstract, published, links, date } }) {
    return <div className="card mb-4">
        <div className="card-content pt-5">
            <div className="content has-text-left"> {/* Added has-text-left class */}
                <p>
                    <span className="title is-5">• {title}</span>
                    {links.paper && <a href={links.paper} target="_blank" rel="noreferrer"> [PAPER]</a>}
                    {links.code && <a href={links.code} target="_blank" rel="noreferrer"> [CODE]</a>}
                </p>
            </div>
            <div className="content mb-2">
                <p className="subtitle is-6 is-italic has-text-left">{authors.join(', ')} ({date})</p> {/* Added has-text-left class */}
            </div>
            {published && <div className="content">
                <p className="subtitle is-7 has-text-left"> {/* Added has-text-left class */}
                    <strong>In:</strong> <span className="is-italic">{published}</span>
                </p>
            </div>}
        </div>
    </div>;
};

export default Paper;