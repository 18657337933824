function ResearchAtoms() {
    return <section className="container section">
        <h2 className="is-size-3 mb-5">STEM Atoms</h2>
        
        <div className="columns is-multiline mb-4 display-linebreak">
        Electron microscopy generates images where single atoms can be identified. In this line of research we collaborate with chemists and physicists from several institutions, including ETHZ, Leeds University and ICIQ. For their data and under their guidance, we design and apply Convolutional Neural Networks (CNNs).  Our goal: Metallic atom detection in single-atom heterogeneous catalysts for transmission electron microscopy images. First applied to platinum atoms on a carbon support, the proposed model was able to generalise to iron on carbon nitride [a].<br />
        </div>
        <br/><br/>
        <img src="/images/work/atoms_pred.png" alt="Example output of the original model."></img>
        <br/><br/>
        <div className="columns is-multiline mb-4 display-linebreak">
            The predictions made by the model enabled the statistical analysis of important properties. Such as the relation between structure and performance on nanostructured catalysts.

            The same methodology was successfully extended to more metals and supports [b], and improved with Gaussian Mixture Models for a more accurate prediction of metallic catalysts that group very close together (multimers).
        </div>
        <br/><br/>
        <img src="/images/work/atoms_multimer.jpg" alt="Visualisation of the multimer pipeline."></img>
        <br/><br/>
        <div className="columns is-multiline mb-4 display-linebreak">
            To characterize multi-metallic images (supports that have been doped with more than one type of metallic catalyst), we first train a Variational Autoencoder (VAE). Then, we find the different material clusters on it's latent space, using a GMM.
        </div>
        <br/><br/>
        <img src="/images/work/atomsPdNi.jpg" alt="Visualisation of the multi-metallic pipeline."></img>
        <br/><br/>
        <br /><br />
        <div className="columns is-multiline mb-4 display-linebreak">
            The result of this research has been used to validate insights regarding the spatial organisation of metal centers. In turn, this facilitates the engineering of structure-property relationships in SACs.
        </div>
        
        <div className="columns is-multiline mb-4 display-linebreak">
        <a href="https://pubs.acs.org/doi/10.1021/jacs.1c12466">[a] Sharon Mitchell, Ferran Parés, Dario Faust Akl, Sean M. Collins, Demie M. Kepaptsoglou, Quentin M. Ramasse, Dario Garcia-Gasulla, Javier Pérez-Ramírez, and Núria López. J. Am. Chem. Soc. 2022, 144, 18, 8018–8029 Publication Date:March 25, 2022</a>
        <a href="https://onlinelibrary.wiley.com/doi/full/10.1002/adma.202307991">[b] Kevin Rossi, Andrea Ruiz-Ferrando, Dario Faust Akl, Victor Gimenez Abalos, Javier Heras-Domingo, Romain Graux, Xiao Hai, Jiong Lu, Dario Garcia-Gasulla, Nuria López, Javier Pérez-Ramírez, Sharon Mitchell (2023). Quantitative Description of Metal Center Organization and Interactions in Single-Atom Catalysts. Adv. Mater. 2023, 36, 2307991. https://doi.org/10.1002/adma.202307991</a>
        </div>
    </section>;
}

export default ResearchAtoms;
