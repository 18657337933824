import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
import { TiCalendar, TiSortAlphabetically } from "react-icons/ti";

import Pagination from "../../misc/pagination";
import Paper from "./paper";
import publicationsData from "../../../data/publications.json";
import { useState } from "react";

function publicationPassesFilter(publication, filter) {
    if (!filter) return true;

    const title = publication.props.information.title;
    return title.toLowerCase().includes(filter.toLowerCase());
}

function sortCompareFn(first, second, method) {
    let comparison;
    let alsoByDate = false;

    if (method.method === "DATE") {
        comparison = first.props.information.date < second.props.information.date;
        alsoByDate = first.props.information.date === second.props.information.date;
    }

    if (method.method === "ALPHA" || alsoByDate) {
        comparison = first.props.information.title > second.props.information.title;
    }

    comparison = comparison ? 1 : -1;
    if (!method.ascending) comparison = -comparison;
    return comparison;
}

function PublicationsView({ publications, itemsPerPage }) {
    const [searchValue, setSearchValue] = useState("");
    const [selectedPage, setSelectedPage] = useState(1);
    const [sortMethod, setSortMethod] = useState({ method: "DATE", ascending: true });

    // Sort publications by current method and orientation
    publications.sort((e1, e2) => sortCompareFn(e1, e2, sortMethod));
    // Filter publications by text fild (currently only using publication title).
    let pagePublications = publications.filter(publication => publicationPassesFilter(publication, searchValue));
    // Calculate number of pages from the already title-filtered publications.
    const numPages = Math.ceil(pagePublications.length / itemsPerPage);
    const upperBoundary = selectedPage * itemsPerPage;
    const lowerBoundary = upperBoundary - itemsPerPage;
    // Filter publications to show only the ones that correspond to the current page.
    pagePublications = pagePublications.filter((_, idx) => lowerBoundary <= idx && idx < upperBoundary);

    let alphaSort, dateSort;
    if (sortMethod.method === 'ALPHA') {
        alphaSort = sortMethod.ascending ? <FaSortAlphaDown /> : <FaSortAlphaUp />
        dateSort = <TiCalendar />
    } else {
        const calendar = <TiCalendar />;
        dateSort = sortMethod.ascending ? <>{calendar}<BsArrowDown /></> : <>{calendar}<BsArrowUp /></>
        alphaSort = <TiSortAlphabetically />
    }

    function handleChangeSortMethod(method) {
        const ascending = (method === sortMethod.method) ? !sortMethod.ascending : true;
        setSortMethod({ method, ascending });
    }

    return <div>
        <div className="level mb-4" style={{ justifyContent: "flex-start" }}>
            <input value={searchValue} onChange={e => setSearchValue(e.target.value)}
                className="input is-primary" type="text" placeholder="Filter publications..."></input>
            <div className="level">
                <button
                    onClick={() => handleChangeSortMethod("DATE")}
                    className={`button ml-3 ${sortMethod.method === "DATE" ? "is-primary" : ""}`}>
                    {dateSort}</button>
                <button
                    onClick={() => handleChangeSortMethod("ALPHA")}
                    className={`button ml-3 ${sortMethod.method === "ALPHA" ? "is-primary" : ""}`}>
                    {alphaSort}</button>
            </div>
        </div>

        {pagePublications}
        {numPages > 1 && // Show Paginator <=> there is more than one page to display
            <Pagination numPages={numPages} selectedPage={selectedPage} onChangePage={setSelectedPage} />
        }
    </div>
}

function Publications() {
    const publications = publicationsData.map(publication => <Paper key={publication.url} information={publication} />)

    return <section className="container section">
        <h2 className="is-size-2 mb-4">Publications</h2>
        <PublicationsView publications={publications} itemsPerPage={5} />
    </section>;
};

export default Publications;