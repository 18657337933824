import workData from "../../../data/work.json";
import './works.css'
import { Timeline } from 'react-twitter-widgets'

function Work({ work }) {
    const summary = work.summary.length < 400 ? work.summary : work.summary.substring(0, 400) + "...";
    return <article className="tile is-child notification is-vertical is-info">
                <a href={work.link} style={{"text-decoration": "none"}}>
                <div className="columns">
                    <div className="column is-8">
                        <p className="title" style={{"margin": "0"}}>{work.title}</p>
                    </div>
                    <div className="column is-4">
                        <img className="is-64x64" src={`${process.env.PUBLIC_URL}/images/work/${work.img}`} alt={work.img}/>
                    </div>
                </div>
                </a>
                <p className="subtitle cuttext" style={{"margin": "0"}}>{summary}</p>
            </article>;
}

function Works() {
    const works = workData.map(work => <Work key={work.title} work={work} />);
    let worksReordered = [];
    worksReordered[0] = [];
    worksReordered[1] = [];
    for(let i = 0;i<works.length;i++) {
        const column = Math.floor(i % 2);
        worksReordered[column].push(works[i]);
    }

    return <div className="tile container is-centered">
            {worksReordered.map(worksForColumn => (
                <div className="tile is-parent is-4 is-vertical">
                    {worksForColumn}
                </div>
            ))}
        <div className="twitter-title tile is-4">
            <article className="tile is-child notification is-white">
            <Timeline
                dataSource={{
                    sourceType: 'profile',
                    screenName: 'hpai_bsc'
                }}
                options={{
                    height: '500',
                    width: '300'
                }}
            />
            </article>
        </div>
    </div>;
}

export default Works;