import './App.css';

import NavBar from "./components/navbar";
import ButtonTop from './components/misc/buttonTop';
import Footer from './components/footer';
import Pages from './components/pages';
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <NavBar />
        <Pages />
      </Router>
      <ButtonTop />
      <Footer />
    </div>
  );
}

export default App;
