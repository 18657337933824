import membersData from "../../data/members.json";
import Members, {FormerMembers} from "./members";
import "./about.css";



function AboutText() {
    return <div>
        <p>
            The HPAI is a research group within the CS department at the Barcelona Supercomputing Center (BSC), a public research institution in charge of managing one of the largest HPC clusters in Europe, the MareNostrum. We work at the intersection of High-Performance Computing (HPC) and AI, with a strong focus on social commitment, fundamental research and open dissemination. Our main lines of research include trustworthy and explainable AI, medical and non-medical image processing, foundation models, and agent-based systems.
        </p>
        <p>
            Founded in 2018, the HPAI has already obtained the SGR quality seal for emerging research groups by the Catalan government, and continues to grow and improve at an accelerated pace. If you are interested in our work, or willing to explore collaborations, do not hesitate to contact us.
        </p>
        <h3><center>HPAI in numbers</center></h3>
        <p><ol>The group publishes about <b>10</b> papers per year.
            <li>Those papers published since 2020 have gathered over <b>1,000</b> citations as of 2024.</li>
            <li>HPAI has participated in a <b>dozen</b> competitive funding projects (European, National and Regional).</li>
            <li>It has also conducted several private contracts with tech companies and institutions, like Intel, Lenovo, IBM, ETHZ, Asepeyo, Inkan.</li>
            <li>The group produces an average of <b>six</b> undergrad and master thesis per year.</li>
        </ol></p>
    </div>;
}

function About() {
    return <section>
        <div className="group-photo" >
            <img id="hpc" src={`${process.env.PUBLIC_URL}/images/members/group_photo.jpg`} alt="group_photo.jpg"
                                className="group-photo" />
        </div>
        <div className="container section">
        <AboutText/>
        <h2 className="is-size-2 mb-3">Members</h2>
        <Members/>
        <FormerMembers/>
        </div>
    </section>;
};

export default About;