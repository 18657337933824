function ResearchFocus() {
    return <section className="container section">
        <h2 className="is-size-3 mb-5">Focus and Mosaics</h2>
        <div className="columns is-multiline mb-4 display-linebreak">
            <p>
                The Focus is a novel metric proposed for evaluating feature attribution methods. The three elements needed to compute
                the Focus are: a feature attribution method to be evaluated, a trained classification model to be explained and a set of
                mosaics samples. The mosaics are composed of a non-overlapping two by two grid of images, where two images belong to the
                target class (i.e., the class the explainability method is expected to explain) and the other two are randomly chosen from
                the remaining classes.
                <br /><br />
                Then, the Focus is computed as the positive relevance of the target class images with respect to the positive relevance of the
                whole mosaic.
            </p>
        </div>
        <embed src="/images/work/focus_formula.png"  width="1000px"  />
        <div>
            <p>
                Therefore, given the previous question and a good underlying model, a reliable feature attribution method
                should be able to concentrate most of its explanation relevance on the two appropriated images of the
                mosaic (i.e., <math>img<sub>1</sub></math> and <math> img<sub>4</sub></math>).
                <br/><br/>
                The Focus was used to conduct an evaluation on six popular XAI methods (GradCAM, LRP, SmoothGrad, LIME,
                GradCAM++, IG) across various architectures and datasets. Our findings indicated that some methods
                (e.g., GradCAM, LRP) consistently provide reliable explanations, while others (e.g., SmoothGrad, IG)
                may produce less accurate or class-agnostic explanations. Further details can be found <a href="https://arxiv.org/pdf/2109.15035.pdf"> here</a>.
                <br/><br/>
                As the Focus only took into account positive relevancies, we improved this score to also consider
                negative relevances. The idea was to move from attributions to classifications scores, categorizing
                attributions into "relevant" and "not relevant" classes. This approach allowed us to employ widely used
                metrics in the evaluation of classification models, such as accuracy, precision, and recall, but in our
                case, for the assessment of feature attribution methods. Check out this approach in the following video.

            </p>
        </div>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/nlZwTpZ8wdI?si=hlAki9096OUuIFMP"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
        <div>
            <p>Publications in this research line:
                <ul>
                    <li><p>
                        Arias-Duart, A., Parés, F., Garcia-Gasulla, D., & Giménez-Ábalos, V. (2022, July).
                         <a href="https://arxiv.org/pdf/2109.15035.pdf">
                             Focus! Rating XAI Methods and Finding Biases. </a>
                         In 2022 IEEE International Conference on Fuzzy Systems (FUZZ-IEEE) (pp. 1-8). IEEE.
                    </p></li>
                    <li><p>
                        Arias-Duart, A., Mariotti, E., Garcia-Gasulla, D., & Alonso-Moral, J. M. (2023).
                         <a href="https://openaccess.thecvf.com/content/CVPR2023W/XAI4CV/papers/Arias-Duart_A_Confusion_Matrix_for_Evaluating_Feature_Attribution_Methods_CVPRW_2023_paper.pdf">
                             A Confusion Matrix for Evaluating Feature Attribution Methods. </a>
                        In Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition Workshops (pp. 3708-3713).
                    </p></li>
                    <li><p>
                        Arias-Duart, A., Parés, F., Giménez-Ábalos, V., & Garcia-Gasulla, D. (2022).
                         <a href="https://ebooks.iospress.nl/doi/10.3233/FAIA220355"> Focus and Bias: Will It Blend? </a>
                         In Artificial Intelligence Research and Development (pp. 325-334). IOS Press.
                    </p></li>
                    <li><p>
                        Arias-Duart, A., Gimenez-Abalos, V., Cortés, U., & Garcia-Gasulla, D. (2023).
                         <a href="https://www.mdpi.com/2079-9292/12/14/3066"> Assessing Biases through Visual Contexts. </a>
                         Electronics, 12(14), 3066.
                    </p></li>
                </ul>
            </p>
        </div>
    </section>;
}

export default ResearchFocus;
