import researchData from "../../../data/research.json";
import ResearchCard from "./researchCard";

function Research() {
    const researchCards = researchData.map(repository => <ResearchCard key={repository.url} information={repository} />);

    return <section className="container section">
        <h3 className="is-size-3 mb-3">HPAI - Recent lines of research</h3>
        <div className="is-multiline">
            {researchCards}
        </div>
    </section>;
};

export default Research;