function ResearchLivers() {
    return <section className="container section">
        <h2 className="is-size-3 mb-5">Livers: PSVD rare disease characterization</h2>
        
        <div className="columns is-multiline mb-4 display-linebreak">
        Porto-Sinusoidal Vascular Disorder (PSVD) designates a range of rare liver affectations characterized by the presence of specific portal vein branches alterations in the absence of cirrhosis. 
        
        PSVD is considered a rare disease, which is often misdiagnosed as liver cirrhosis (up to 72% of cases) due to the simuilarity in their symptomps, and the prevalence of the later. Currently, the only way of diagnosing PSVD is by means of a liver biopsy, a painful process that is costly and potentially dangerous.

        In this context, experts in PSVD from Hospital Clinic, and a team from HPAI started a collaboration. The goal was to learn about PSVD particularities, and to offer that knowledge to clinicians to facilitate their work.

        The basis of this work is an anonimized dataset provided by Hospital Clinic, which includes CT scans of healthy, cirrhosis, PSVD and miscellanous patients, with the later containing a variety of other liver pathologies.
        </div>
        
        <br/><br/>
        <img src="/images/work/liver_distrib.png" alt="Distribution of data samples."></img>
        <br/><br/>
        
        <div className="columns is-multiline mb-4 display-linebreak">
        Working with a rare disease limits the amount of available data, and in this case we were restricted to 50 patients per class. These are split between train and validation, stratified to minimize the impact of potential biases. The biases identified and analyzed in this work are:

        * Sex
        * CT Scanner Model
        * Slice count and thickness
        * Radiocontrast

        Although the limited data available does not allow us to validate the absolute abscence of these biases, we did not find strong evidence of their impact in model performance.

        To maximize training samples we follow a slice-wise classifier approach, in which patient levels are extrapolated to all its CT slices. This may induce noise in the data (the pathology may not be visible on all slices), but it is coherent with the highly exploratory nature of this work. A voting mechanism is later employed to determine the label at patient level.

        We trained deep models for several tasks of increasing complexity (healthy vs non-healthy, PSVD vs cirrhosis, all vs all), and integrated XAI methodologies to validate the model behavior (e.g., GradCam, LRP). Focusing on the hardest task (all vs all), results indicate some of the models trained are rather competent at a problem which medical experts are unable to do by sight alone. Notice pre-training with RadImageNet provides a significant boost in performance.
        </div>
        <br/><br/>
        <img src="/images/work/livers_table.png" alt="Table of results."></img>
        <img src="/images/work/liver_cm.png" alt="Confusion Matrix."></img>
        <img src="/images/work/liver_table2.png" alt="Table of results."></img>
        <br/><br/>
        
        <div className="columns is-multiline mb-4 display-linebreak">
        Our analysis of explainability shows consistent results (i.e., high relevance of liver and spleen areas) together with inconclusive evidence (i.e., high relevance of bones and other irrelevant tissue). For this reason, the model is not considered reliable enough as to provide direct support or evidence to clinicians. To advance in that direction, we plan to gather more data, and to reduce the noise in it by adding more restrictive segmentation methodologies.
        </div>

        <br/><br/>
        <img src="/images/work/liver_gradcam.png" alt="Table of results."></img>
        <br/><br/>
        <div className="columns is-multiline mb-4 display-linebreak">
        An interesting insight gained in this study regards the predicted certainty attributed at different CT slices based on their location within the global CT scans. This provides novel information about the location patterns of the pathologies under study, and particularly, of PSVD. As shown below, the visual features of PSVD seem to be more prevalent in the middle section of the CT scan, while the higher portion of the liver (i.e., closer to the head of the patient) seems to be better for identifying cirrhosis and healthy patients.
        </div>
        <br/><br/>
        <img src="/images/work/liver_slices.png" alt="Analysis based on slice depth."></img>
        <br/><br/>
        <div className="columns is-multiline mb-4 display-linebreak">
        The outcome of this work is currently being considered for integration within a decision support system for clinicians, which considers a variety of indicators. In this setting, the proposed model would contribute with one more evidence, to be combined with others, and assessed by the medical expert. Prior to that, the model will be tested using a blind evaluation set. Hopefuly, a paper will shortly follow.
        <br/><br/>
        Cuervo, R. "Deep Learning Approaches for Detecting and Explaining Hepatic Disorders from CT Scans", Master Thesis, 2024. UPC-BarcelonaTECH.
        </div>
    </section>;
}

export default ResearchLivers;
