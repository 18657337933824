function Footer() {
    const address = "Barcelona Supercomputing Center, Plaça Eusebi Güell, 1-3, 08034, Barcelona (Spain)";
    const mail = "hpai@bsc.es";
    const phone = "+34 934 13 75 41";
    const twitter = "https://twitter.com/hpai_bsc"

    return <footer className="footer">
        <div className="container content">
            <h2 className="has-text-white">Contact</h2>
            <div>
                <table className="has-text-white">
                    <tbody>
                    <tr>
                        <td>Address</td>
                        <td>{address}</td>
                    </tr>
                    <tr>
                        <td>Mail</td>
                        <td><a href={`mailto:${mail}`}>{mail}</a></td>
                    </tr>
                    <tr>
                        <td>Phone</td>
                        <td><a href={`tel:${phone}`}>{phone}</a></td>
                    </tr>
                    <tr>
                        <td>Twitter</td>
                        <td><a href={twitter}>HPAI_BSC</a></td>
                    </tr>

                    

                    </tbody>
                </table>
            </div>
        </div>
    </footer>;
};

export default Footer;