import React, { useState } from 'react';
import './pagination.css'; // Import the pagination.css file

function Pagination({ numPages, selectedPage, onChangePage }) {
    const [inputPage, setInputPage] = useState(selectedPage);

    const handleInputChange = (e) => {
        setInputPage(e.target.value);
    };

    const handleGoToPage = () => {
        const pageNumber = parseInt(inputPage);
        if (pageNumber >= 1 && pageNumber <= numPages) {
            onChangePage(pageNumber);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxDisplayedPages = 3; // Maximum number of page numbers to display
        const halfDisplayedPages = Math.floor(maxDisplayedPages / 2);
        let startPage = Math.max(selectedPage - halfDisplayedPages, 1);
        let endPage = Math.min(startPage + maxDisplayedPages - 1, numPages);

        if (endPage - startPage < maxDisplayedPages - 1) {
            startPage = Math.max(endPage - maxDisplayedPages + 1, 1);
        }

        // Always show first and last pages
        if (startPage > 1) {
            pageNumbers.push(
                <button
                    key={1}
                    className={`button pagination-link`}
                    onClick={() => onChangePage(1)}
                >
                    {1}
                </button>
            );
            if (startPage > 2) {
                pageNumbers.push(
                    <span key="ellipsis-start">...</span>
                );
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`button pagination-link ${selectedPage === i ? 'is-primary' : ''}`}
                    onClick={() => onChangePage(i)}
                >
                    {i}
                </button>
            );
        }

        // Always show last page
        if (endPage < numPages) {
            if (endPage < numPages - 1) {
                pageNumbers.push(
                    <span key="ellipsis-end">...</span>
                );
            }
            pageNumbers.push(
                <button
                    key={numPages}
                    className={`button pagination-link`}
                    onClick={() => onChangePage(numPages)}
                >
                    {numPages}
                </button>
            );
        }

        return pageNumbers;
    };

    return (
        <ul className="pagination-container">
            <li
                className={`pagination-item ${selectedPage === 1 ? 'disabled' : ''}`}
                onClick={() => onChangePage(selectedPage - 1)}
            >
                <div className="arrow left"></div>
            </li>
            {renderPageNumbers()}
            <li
                className={`pagination-item ${selectedPage === numPages ? 'disabled' : ''}`}
                onClick={() => onChangePage(selectedPage + 1)}
            >
                <div className="arrow right"></div>
            </li>
        </ul>
    );
}

export default Pagination;