function ResearchEmbryo() {
    return <section className="container section">
        <h2 className="is-size-3 mb-5">Navigating Infertility with Artificial Intelligence</h2>
        <div className="columns is-multiline mb-4 display-linebreak">
            <p>
            Infertility is a common reproductive health problem that affects millions of people worldwide, causing social, psychological, 
            physical and economic distress to the ones seeking to conceive. In the coming years, infertility rates are projected to grow 
            due to environmental and lifestyle factors. In this landscape, In Vitro Fertilization (IVF) emerges as a key technology to 
            overcome such challenges, involving the fertilization of an egg with sperm in a laboratory setting and the posterior transfer 
            of the resulting embryos into the patient’s uterus.
            <br /><br />
            The main challenge of IVF is the selection of the embryos that will be either selected for implantation, frozen 
            (for later implantation) or discarded (if they exhibit undesirable features). This selection is to be performed during the early 
            hours after embryo insemination, typically between three and five days after. During this time, embryos are monitored in time-lapse 
            imaging incubators (TLI), facilitating uninterrupted embryo growth within stable culture conditions. This technology offers a dynamic 
            perspective on in-vitro embryonic development, improving the clinical effectiveness of IVF.
            <figure>
                <embed src="/images/work/embryo_cohort.png" width="800px" />
                <figcaption>Possible outcomes in the embryo selection process.</figcaption>
            </figure>
            <br /><br />
            Artificial Intelligence (AI), and specially Deep Learning (DL), due to its pattern recognition abilities, have recently been considered 
            to assist in the embryo assessment and selection process. AI has the potential to facilitate and improve the process of embryo selection, 
            increasing the implantation success rates, and reducing the chances of multiple pregnancies. AI can also mitigate inter and intra-observer 
            variability, making results more reproducible and comparable. Finally, AI can help reduce the financial, physical and emotional burden on patients, 
            by optimising the treatment plan and minimising the need for repeated cycles of IVF.
            <br /><br />
            Nonetheless, whenever AI is considered for an application which directly affects human life, trustworthiness must be assured. 
            In our study, we review the current work on AI-assisted embryo selection with special focus on Explainable Artificial Intelligence (XAI) and 
            its deployment in a real clinical setting.
            </p>
        </div>

        <h2 className="is-size-3 mb-5">XAI in Embryo Selection Models</h2>
        <div className="columns is-multiline mb-4 display-linebreak">
            <p>
            Upon reviewing existing studies, we distinguish between two types of explainability methods used in this domain: post-hoc and intrinsic. 
            Post-hoc methods are applied after the model has been trained, while intrinsic methods are integrated into the model’s architecture and design.
            <br /><br />
            Among post-hoc methods, there are model-specific approaches like CAM or Grad-CAM, which use neural network activations, and gradients in the 
            latter case, to create saliency maps. On the other hand, model-agnostic methods such as LIME and SHAP generate explanations by tweaking inputs 
            and observing resulting changes in the model’s output. Within intrinsic methods, attention-based explainability stands out. This approach visualizes 
            the attention layers of the network to generate saliency maps.
            <figure>
                <embed src="/images/work/embryo_taxonomy.png" width="800px" />
                <figcaption>Taxonomy of XAI methods in embryo selection.</figcaption>
            </figure>
            </p>
        </div>
        
        <h2 className="is-size-3 mb-5">Key Insights</h2>
        <div className="columns is-multiline mb-4 display-linebreak">
            <p>
            We found out that the results obtained from the explainability methods are inconsistent throughout the studies. Some studies assert 
            a positive correlation between XAI outcomes and pertinent biological features. However, a counter-narrative emerges, with other studies 
            failing to establish robust biological backing. Take, for instance, the case of saliency maps. These visual aids often point to areas in 
            images that aren’t biologically relevant or produce maps with unclear attributions, making it challenging to pinpoint highlighted structures. 
            This ambiguity poses a considerable risk of confirmation bias.
            <br /><br />
            Furthermore, we studied the engagement of clinical experts in assessing both the models and the results generated by XAI methods. Among the 
            11 identified studies, a mere 2 incorporated the insights of clinical experts in their result evaluations.
            <figure>
                <embed src="/images/work/embryo_findings_3.png" width="400"/>
                <figcaption>Number of studies that have performed a clinical evaluation.</figcaption>
            </figure>
            <br /><br />
            Our exploration extended to the implementation and comparison of multiple XAI methods. We observed that a majority of studies exclusively 
            adopted a single XAI method, thereby constraining the reliability and robustness of the explanations.
            <br /><br />
            <figure>
                <embed src="/images/work/embryo_findings_2.png" width="400" />
                <figcaption>Quantity of XAI methods utilized in the studies.</figcaption>
            </figure>
            </p>
        </div>

        <h2 className="is-size-3 mb-5">Integrating XAI in Decision Support Systems</h2>
        <div className="columns is-multiline mb-4 display-linebreak">
            <p>
            Furthermore, we explored how explainability can be integrated in models which are to be deployed in a clinical context as decision support systems.
            <br /><br />
            <figure>
                <embed src="/images/work/embryo_integrating_xai.png" width="800" />
                <figcaption>Evaluated dimensions.</figcaption>
            </figure>
            <br /><br />
            Firstly, we noted that non-transparent models can create responsibility gaps, complicating the assessment of decision-making stages. This lack of 
            transparency makes it challenging to identify who bears responsibility in the event of an inaccurate diagnosis or, in this case, embryo selection.
            <br /><br />
            We also examined the significance of trust in the acceptance of these systems. Skepticism among experts towards AI systems can impede their adoption 
            in clinical settings, potentially leading to disuse. Conversely, excessive trust in these systems must be avoided to prevent automation bias, where 
            individuals become less engaged in the decision-making process.
            <br /><br />
            Lastly, we investigated the role of patients undergoing IVF treatment. We advocate for transparent communication with patients, informing them when 
            AI systems aid in decision-making. Explanations should be presented in an accessible manner to ensure patients, without technological expertise, can 
            comprehend them. Relevant explainability results should be shared under the guidance of clinicians for proper interpretation and contextualization.
            </p>
        </div>

        <h2 className="is-size-3 mb-5">Wrapping up</h2>
        <div className="columns is-multiline mb-4 display-linebreak">
            <p>
            In summary, our study highlights key takeaways. Firstly, it’s crucial to validate the explanations generated by the AI model with input from clinical 
            experts to ensure their value and consistency. We also advocate for exploring and comparing various explainability methods.
            <br /><br />
            Equally important is the notion that AI architectures should be inherently designed with explainability in mind right from the start of the development 
            process. For instance, modular networks offer a way to enhance explainability by training feature-specific sub-models.
            <br /><br />
            Furthermore, we emphasize the need for explanations to adhere to established annotation and evaluation guidelines within the field. Lastly, we stress the 
            importance of educating and training experts on the utilization of these systems, enabling them to comprehend both capabilities and limitations.
            </p>
        </div>

        <div className="columns is-multiline mb-4 display-linebreak">
        <a href="https://arxiv.org/pdf/2308.02534">[a] Urcelay, Lucia, Daniel Hinjos, Pablo A. Martin-Torres, Marta Gonzalez, Marta Mendez, Salva Cívico, Sergio Álvarez-Napagao, and Dario Garcia-Gasulla. "Exploring the Role of Explainability in AI-Assisted Embryo Selection." rtificial Intelligence Research and Development: Proceedings of the 25th International Conference of the Catalan Association for Artificial Intelligence. Vol. 375. IOS Press, 2023.</a>
        </div>

    </section>;
}

export default ResearchEmbryo;
