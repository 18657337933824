function ResearchMAMe() {
    return <section className="container section">
        <h2 className="is-size-3 mb-5">Museum Art Mediums dataset</h2>
        
        <div className="columns is-multiline mb-4 display-linebreak">
        The real world of visual data is not composed by uniformly squared, low-resolution images. And yet, most current research is in computer vision is done under such conditions. The Museum Art Mediums (MAMe) dataset was designed to facilitate complimentary research, and built using high-resolution, variable shaped images. The 37,407 samples were gathered from three different museums, and it’s 29 labels were tuned by experts from Universitat de Barcelona Art Department.
        </div>
        <br/><br/>
        <img src="/images/work/mame.png" alt="Example of samples from the MAMe dataset."></img>
        <br/><br/>
        <div className="columns is-multiline mb-4 display-linebreak">
        For more detail, and to access the data itself, visit the <a url="https://hpai.bsc.es/MAMe-dataset/"> MAMe dataset webpage</a>

        </div>
        Paper: <a href="https://arxiv.org/pdf/2007.13693">Pares, F., Arias-Duart, A., Garcia-Gasulla, D., Campo-Frances, G., Viladrich, N., Ayguade, E., & Labarta, J. (2022). The MAMe dataset: on the relevance of high resolution and variable shape image properties. Applied Intelligence, 52(10), 11703-11724.</a>
    </section>;
}

export default ResearchMAMe;
