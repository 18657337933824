import { BiArrowToTop } from "react-icons/bi";
import { animateScroll as scroll } from "react-scroll";
import { useState } from "react";

function ButtonTop() {
    const minOppacity = 200;
    const fullOppacity = 400;
    const buttonOpacity = () => {
        if (window.pageYOffset < minOppacity) return 0;
        else return Math.min(fullOppacity, window.pageYOffset) / fullOppacity;
    }

    const [opacity, setOpacity] = useState(buttonOpacity());
    window.onscroll = () => { setOpacity(buttonOpacity()) };

    return <button onClick={() => scroll.scrollToTop()} className="button is-floating is-large is-primary"
        style={{ opacity: opacity, display: opacity > 0 ? 'inline-flex' : 'none' }}>
        <i><BiArrowToTop className="icon-aligned" /></i>
    </button>;
}

export default ButtonTop;