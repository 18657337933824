function ResearchFluidC() {
    return <section className="container section">
        <h2 className="is-size-3 mb-5">Fluid Communities</h2>
        
        <div className="columns is-multiline mb-4 display-linebreak">
        Fluid Communities is a community detection algorithm inspired by the dynamic interactions of fluids in an environment. This algorithm implements the principles of expansion and contraction, mirroring the fluid's response to interactions. At its core, it is based on the highly efficient propagation methodology, achieving sota performance on benchmarks in computational scalability.        <br />
        </div>
        <br/><br/>
        <img src="/images/work/fluidc_algorithm.png" alt="Example of the Fluid Communities algorithm."></img>
        <br/><br/>
        <div className="columns is-multiline mb-4 display-linebreak">
        Remarkably efficient, "Fluid Communities" excels in detecting communities within synthetic graphs, with accuracy rates that rival the best alternatives in the field. This algorithm stands out as particularly versatile within the propagation-based category, capable at identifying a varying number of communities within a network.
        </div>
        <br/><br/>
        <img src="/images/work/fluidc_scale.png" alt="Scalability plots of the Fluid Communities algorithm."></img>
        <br/><br/>
        <div className="columns is-multiline mb-4 display-linebreak">    
        Furthermore, to measure its significance in a non-supervised setting (where there is no such thing as a golden ground truth), an evaluation was conducted on the diversity of the communities identified by "Fluid Communities” with respect to those found by other algorithms." Results indicate the communities found by “FluidC” are rather distinct, and therefore, always worth exploring. All these feature explain the popularity of this work within the community detection field. 
        </div>
        <br/><br/>
        <img src="/images/work/fluidc_nmi.png" alt="Normalized Mutual Information of Fluid Communities with other community detection algorithms."></img>
        <br/><br/>
        
        <br /><br />
        
        <div className="columns is-multiline mb-4 display-linebreak">
        <a href="https://arxiv.org/abs/1703.09307">Parés, F., Garcia-Gasulla, D., Vilalta, A., Moreno, J., Ayguadé, E., Labarta, J., ... & Suzumura, T. (2018). Fluid communities: A competitive, scalable and diverse community detection algorithm. In Complex Networks & Their Applications VI: Proceedings of Complex Networks 2017 (The Sixth International Conference on Complex Networks and Their Applications) (pp. 229-240). Springer International Publishing.</a>
        </div>
    </section>;
}

export default ResearchFluidC;
