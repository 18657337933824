import { Route, Switch } from "react-router-dom";

import FinalProjects from "./pages/final-projects/final-projects";
import Collaborations from "./pages/collaborations";
import Home from "./pages/home/home";
import Members from "./pages/members";
import About from "./pages/about";
import Publications from "./pages/publications/publications";
import React from "react";
import Software from "./pages/software/software";
import Research from "./pages/research/research";
import projectPageMappings from "./pages/project-page/mappings";

function Pages() {
    let projectPageRoutes = projectPageMappings();

    return <Switch>
        {projectPageRoutes}
        <Route exact path="/research" component={Research} />
        <Route exact path="/about" component={About} />
        <Route exact path="/members" component={Members} />
        <Route exact path="/publications" component={Publications} />
        <Route exact path="/software" component={Software} />
        <Route exact path="/collaborations" component={Collaborations} />
        <Route exact path="/final-projects" component={FinalProjects} />
        <Route path="/" component={Home} />
    </Switch>;
};

export default Pages;
