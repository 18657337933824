function ResearchPANs() {
    return <section className="container section">
        <h2 className="is-size-3 mb-5">Padding Aware Neurons (PANs)</h2>
        <div className="columns is-multiline mb-4 display-linebreak">
        PANs are a type of filter that emerges in convolutional layers when using static padding (e.g., zero padding). These neurons learn to recognize the location of input border, and adapt their behavior around the existence of this signal. Significantly, PANs appear in convolutional layers at any depth, introducing a spatial bias that is rarely useful for the task at hand. Check out the poster and/or the paper below for details on how to detect them, on why are they important, and how to avoid them.
        <br />
        <em>If you really understand convolutions, figuring out the enigma of the poster should be doable.</em></div>
        <center><embed src="/images/work/PANs_poster.pdf" width="800px" height="1150px" /></center>
        <br /><br />
        <div className="columns is-multiline mb-4 display-linebreak">
        GARCIA-GASULLA, Dario; GIMENEZ-ABALOS, Victor; MARTIN-TORRES, Pablo. Padding Aware Neurons. En 2023 IEEE/CVF International Conference on Computer Vision Workshops (ICCVW). IEEE Computer Society, 2023. p. 99-108.
        <a href="https://openaccess.thecvf.com/content/ICCV2023W/VIPriors/papers/Garcia-Gasulla_Padding_Aware_Neurons_ICCVW_2023_paper.pdf">[PAPER]</a>
        </div>
    </section>;
}

export default ResearchPANs;
