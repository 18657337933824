import { FiBook, FiStar } from "react-icons/fi";

function Repository({ repository: r }) {
    const tags = r.tags.sort().map(tag => {
        return <span className="tag is-white mr-1 mt-1 tag-border" key={tag}>{tag}</span>;
    });

    let stars = r.stargazers_count;
    if (stars >= 1000) {
        const integerPart = Math.floor(stars / 1000);
        const decimalPart = Math.floor(stars % 1000 / 100);
        stars = `${integerPart}.${decimalPart}K`;
    }

    return (
        <div className="column is-full-mobile is-half-tablet is-one-third-desktop">
            <div className="github-border p-0">
                <div className="card-content">
                    <div>
                        <div className="mb-1">
                            <FiBook className="mr-2 icon-aligned" />
                            <a href={r.html_url} target="_blank" rel="noreferrer" className="github-title">
                                {r.full_name.split('/')[0]}/<b>{r.name}</b>
                            </a>
                        </div>
                        <p className="mb-1 is-6">{r.description}</p>
                        {false && <div className="mb-1"><FiStar className="mr-1 mb-1 icon-aligned" />{stars}</div>}
                        {tags}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Repository;