import collaboratorsData from "../../data/collaborators.json";

function Collaborator({ collaborator }) {
    return <div className="column is-full-mobile is-half-tablet is-one-quarter-desktop has-text-centered">
        <a href={collaborator.link} target="_blank" rel="noreferrer" className="contact-image">
            {/* <p className="heading is-6">{collaborator.name}</p> */}
            <figure className="image is-128x128 center">
                <img src={`${process.env.PUBLIC_URL}/images/collaborators/${collaborator.img}`} alt={`${collaborator.name} Logo`} />
            </figure>
        </a>
    </div>;
}

function Collaborations() {
    const collaborators = collaboratorsData.map(collaborator => <Collaborator key={collaborator.name} collaborator={collaborator} />);

    return <section className="container section">
        <h2 className="is-size-2 mb-6">Industrial Collaborations</h2>
        <div className="columns is-multiline">
            {collaborators}
        </div>
    </section>;
};

export default Collaborations;